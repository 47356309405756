:root {
    /* Color codes */
    --color-code-primary-120: 0, 32, 82; // #002052
    --color-code-primary-140: 0, 69, 134; // #004586 //** previous blue color
    --color-code-primary: 12, 43, 82; //#0c2b52 //* new FTZ blue color
    --color-code-primary-80: 51, 106, 158; // ##336A9E
    --color-code-primary-60: 102, 143, 182; // #668FB6
    --color-code-primary-40: 153, 181, 207; // #99B5CF
    --color-code-primary-20: 204, 218, 231; // #CCDAE7
    --color-code-primary-10: 229, 236, 242; // #E5ECF2
    --color-code-primary-5: 236, 242, 247; // #ECF2F7

    // --color-code-orange: 255, 78, 0; // #FF4E00 //** previous orange color
    --color-code-orange: 250, 119, 12; //#FA770C //** new orange color
    --color-code-orange-70: 255, 113, 51; // #FF7133
    --color-code-orange-50: 255, 149, 102; // #FF9566
    --color-code-orange-30: 255, 184, 153; // #FFB899
    --color-code-orange-10: 255, 220, 204; // #FFDCCC

    --color-code-yellow-120: 255, 189, 0; // FFE500
    --color-code-yellow: 255, 189, 0; // #FFBD00
    --color-code-yellow-70: 255, 187, 0; // #FFCA33
    --color-code-yellow-50: 255, 215, 102; // #FFD766
    --color-code-yellow-30: 255, 215, 102; // #FFE599
    --color-code-yellow-10: 255, 242, 204; // #FFF2CC

    --color-code-green: 7, 201, 122; // #07C97A
    --color-code-green-70: 106, 223, 175; // #6ADFAF
    --color-code-green-50: 168, 236, 209; // #A8ECD1
    --color-code-green-30: 193, 241, 222; // #C1F1DE
    --color-code-green-20: 220, 239, 226;
    --color-code-green-10: 218, 247, 235; // #DAF7EB

    --color-code-green-dark: 0, 102, 78; // #00664E
    --color-code-green-dark-70: 51, 133, 113; // #338571
    --color-code-green-dark-50: 102, 163, 149; // #66A395
    --color-code-green-dark-30: 153, 194, 184; // #99C2B8
    --color-code-green-dark-10: 204, 224, 220; // #CCE0DC

    --color-code-black: 31, 31, 31; // #1f1f1f
    --color-code-black-70: 98, 98, 98; // #626262
    --color-code-black-50: 168, 168, 168; // #a8a8a8
    --color-code-black-30: 216, 216, 216; // #d8d8d8
    --color-code-black-10: 236, 236, 236; // #ececec
    --color-code-black-5: 248, 248, 248; // #f8f8f8

    --color-code-red: 255, 51, 51; // #FF3333
    --color-code-red-70: 255, 92, 92; // #FF5C5C
    --color-code-red-50: 255, 133, 133; // #FF8585
    --color-code-red-30: 255, 173, 173; // #FFADAD
    --color-code-red-10: 255, 214, 214; // #FFD6D6

    --color-code-white: 255, 255, 255; // #fff
    --color-code-white-dimmed: 252, 252, 252;

    /* ==============================
   Colors
   ================================= */
    --color-primary: rgb(var(--color-code-primary));
    --color-primary-120: rgb(var(--color-code-primary-120));
    --color-primary-140: rgb(var(--color-code-primary-140));
    --color-primary-80: rgb(var(--color-code-primary-80));
    --color-primary-60: rgb(var(--color-code-primary-60));
    --color-primary-40: rgb(var(--color-code-primary-40));
    --color-primary-20: rgb(var(--color-code-primary-20));
    --color-primary-10: rgb(var(--color-code-primary-10));
    --color-primary-5: rgb(var(--color-code-primary-5));

    --color-orange: rgb(var(--color-code-orange));
    --color-orange-70: rgb(var(--color-code-orange-70));
    --color-orange-50: rgb(var(--color-code-orange-50));
    --color-orange-30: rgb(var(--color-code-orange-30));
    --color-orange-10: rgb(var(--color-code-orange-10));

    --color-yellow-120: rgb(var(--color-code-yellow-120));
    --color-yellow: rgb(var(--color-code-yellow));
    --color-yellow-70: rgb(var(--color-code-yellow-70));
    --color-yellow-50: rgb(var(--color-code-yellow-50));
    --color-yellow-30: rgb(var(--color-code-yellow-30));
    --color-yellow-10: rgb(var(--color-code-yellow-10));

    --color-green: rgb(var(--color-code-green));
    --color-green-70: rgb(var(--color-code-green-70));
    --color-green-50: rgb(var(--color-code-green-50));
    --color-green-30: rgb(var(--color-code-green-30));
    --color-green-20: rgb(var(--color-code-green-20));
    --color-green-10: rgb(var(--color-code-green-10));

    --color-green-dark: rgb(var(--color-code-green-dark));
    --color-green-dark-70: rgb(var(--color-code-green-dark-70));
    --color-green-dark-50: rgb(var(--color-code-green-dark-50));
    --color-green-dark-30: rgb(var(--color-code-green-dark-30));
    --color-green-dark-10: rgb(var(--color-code-green-dark-10));

    --color-black: rgb(var(--color-code-black));
    --color-black-70: rgb(var(--color-code-black-70));
    --color-black-50: rgb(var(--color-code-black-50));
    --color-black-30: rgb(var(--color-code-black-30));
    --color-black-10: rgb(var(--color-code-black-10));
    --color-black-5: rgb(var(--color-code-black-5));

    --color-red: rgb(var(--color-code-red));
    --color-red-70: rgb(var(--color-code-red-70));
    --color-red-50: rgb(var(--color-code-red-50));
    --color-red-30: rgb(var(--color-code-red-30));
    --color-red-10: rgb(var(--color-code-red-10));

    --color-white: rgb(var(--color-code-white));
    --color-white-dimmed: rgb(var(--color-code-white-dimmed));

    --color-success: var(--color-green);
    --color-success-light: var(--color-green-20);
    --color-warning: var(--color-yellow-120);
    --color-warning-dark: rgb(193, 143, 0);
    --color-error: var(--color-red);

    --color-line: var(--color-black-30);

    --color-overlay-background-black: rgba(var(--color-code-black), 0.5);
    --color-overlay-background-white: rgba(var(--color-code-white-dimmed), 0.5);

    --color-disabled: var(--color-black-30);
    --color-background: var(--color-white-dimmed);
    --color-background-grey: var(--color-black-5);
    --color-background-blue: var(--color-primary-5);
    --color-background-green: var(--color-green-10);
    --color-background-yellow: var(--color-yellow-10);
    --color-background-error: var(--color-red-10);
    --color-background-warning: rgb(var(--color-code-yellow-10));
}
