:root {
    --border-radius: 4px;

    /* Layout */
    --layout-header: 70px;

    --operational-notifications-height: 35px;

    /* Grid */
    --grid-gutter-width: 50px;
    --grid-gutter-half: calc(var(--grid-gutter-width) / 2);

    /* Mobile Spacing */
    --mobile-spacing: 15px;

    /* z-index */
    --z-index-very-low: 1;
    --z-index-low: 10;
    --z-index-normal: 50;
    --z-index-above-normal: 75;
    --z-index-medium: 100;
    --z-index-high: 1000;

    --z-index-header: calc(var(--z-index-medium) + 1);
    --z-index-navigation: calc(var(--z-index-medium) - 1);
    --z-index-loader: calc(var(--z-index-navigation) - 1);
    --z-index-tooltip: calc(var(--z-index-high) + 1);
    --z-index--operational-notifications: var(--z-index-high);
}

@include media-breakpoint-up(lg) {
    :root {
        /* z-index */
        --z-index-header: var(--z-index-medium);
        --z-index-navigation: var(--z-index-medium);
        --z-index-loader: calc(var(--z-index-medium) - 1);

        /* Layout */
        --layout-aside: 220px;
        --layout-aside-wide: min(calc(100% - var(--layout-aside) - 50px), 1325px);
        --layout-aside-small: min(calc(100% - var(--layout-aside) - 50px), 430px);
        --layout-aside-medium: min(calc(100% - var(--layout-aside) - 50px), 800px);
        --layout-header: 94px;

        /* Features */
        --feature-price-visible: visible;
    }
}
