:root {
    --input-height: 40px;
    --input-background-color: var(--color-white);

    --input-border-default: 1px solid var(--color-primary-40);
    --input-border-hover: 1px solid var(--color-primary-60);
    --input-border-focus: 1px solid var(--color-primary-120);
    --input-border-active: 1px solid var(--color-primary);
    --input-border-disabled: 1px solid var(--color-disabled);
    --input-border-error: 1px solid var(--color-error);

    --input-placeholder-color: var(--color-primary-40);
    --input-disabled-text-color: var(--color-disabled);

    --input-padding-side: 15px;
}
