@import './reset-bootstrap';

body {
    font-family: var(--font-main), sans-serif;
    font-size: var(--base-font-size);
    line-height: 1.5;
    background-color: var(--color-white);
    color: var(--body-color);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overscroll-behavior: none;
    overflow-y: scroll;
    overflow-wrap: break-word;

    &.no-scroll {
        overflow: hidden;
    }
}

.container-fluid {
    max-width: map-get($map: $container-max-widths, $key: xxl);
    padding: 0 var(--grid-gutter-half);
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-up(lg) {
        padding: 0 var(--grid-gutter-width);
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: var(--font-weight-semi-bold);
}

img {
    display: block;
    max-width: 100%;
}

a,
.link {
    color: var(--color-primary);
    font-weight: var(--font-weight-semi-bold);
    text-decoration: none;

    &:hover {
        text-decoration: none;
        color: var(--color-primary-60);
    }
}

.errorMessage {
    display: block;
    color: var(--color-red);
    font-size: 12px;
}

.link-underline {
    text-decoration: underline;
}

b,
strong {
    font-weight: var(--font-weight-semi-bold);
}

hr {
    background-color: var(--color-black-30);
    height: 1px;
    border: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
    -moz-appearance: textfield; /* Firefox */
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
}

.skeleton-background {
    display: inline-block;
    height: 1em;
    position: relative;
    overflow: hidden;
    background-color: #dddbdd;

    &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(90deg, rgba(#fff, 0) 0, rgba(#fff, 0.2) 20%, rgba(#fff, 0.5) 60%, rgba(#fff, 0));
        animation: skeleton-shimmer 2s infinite;
        content: '';
    }

    @keyframes skeleton-shimmer {
        100% {
            transform: translateX(100%);
        }
    }
}

@media print {
    @page {
        margin: 2cm 1cm;
    }

    // removes cookie banner from print
    #Coi-Renew {
        display: none !important;
    }
}

.printRoot {
    @media screen {
        max-width: 1200px;
        margin: 0 auto;
        padding: 50px;
    }

    @media print {
        page-break-after: always;
    }
}

.atlwdg-trigger {
    background-color: var(--color-primary) !important;
    z-index: var(--z-index-high);
}
