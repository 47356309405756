caption,
.caption,
.title {
    font-size: 10px;
    line-height: 14px;
    font-weight: var(--font-weight-semi-bold);
    color: var(--body-color);
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    letter-spacing: 0.6px;
    display: block;
}

blockquote .quote {
    font-size: 40px;
    font-weight: var(--font-weight-light);
    font-style: italic;
    line-height: 50px;
    letter-spacing: 0.32px;
}

.image-caption {
    font-size: var(--base-font-size);
    color: var(--color-black-50);
}

small,
.small-body {
    font-size: 13px;
}

legend,
.legend {
    font-size: 18px;
    font-weight: var(--font-weight-semi-bold);
    line-height: 28px;
    margin-bottom: 20px;
}
