table,
.table {
    border-collapse: collapse;
    width: 100%;
}

thead,
.thead {
    background-color: var(--color-primary-10);
    box-shadow: var(--shadow-focus);
}

.tableClean {
    thead,
    .thead {
        background-color: transparent;
        box-shadow: none;
    }

    th {
        border-left: none;
    }
}

tbody,
.tbody {
    font-size: 13px;
    letter-spacing: 0;
    line-height: 21px;
    tr:nth-child(even) {
        background-color: var(--color-black-5);
    }
}

td,
th {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
}

td {
    height: 50px;
}

tr {
    height: 45px;
    border-bottom: 1px solid var(--color-line);

    &::nth-child(even) {
        background-color: var(--color-black-5);
    }
}

th {
    font-size: 10px;
    font-weight: var(--font-weight-semi-bold);
    letter-spacing: 0.6px;
    line-height: 14px;
    text-transform: uppercase;
    border-left: 1px solid var(--color-primary-20);

    &:first-child {
        border-left: none;
    }
}

.tablePrint {
    border-collapse: separate;
    border-spacing: 0 5px;

    td,
    th {
        padding: 0 10px;
        height: 20px;

        font-size: 10px;
        letter-spacing: 1px;
        line-height: 12px;
        font-family: var(--font-monospace);
        &:first-child {
            padding-left: 0;
        }
        &:last-child {
            padding-right: 0;
        }
    }

    thead {
        background-color: transparent;
        box-shadow: none;

        th {
            font-weight: var(--font-weight-semi-bold);
            text-transform: uppercase;
            border-left: none;
            padding-bottom: 5px;
            border-bottom: 1px solid var(--color-black-50);
        }
    }

    tr {
        height: auto;
        border-bottom: none;
        background-color: transparent !important;
        td {
            vertical-align: middle;
        }
    }
}
