.cleanButton {
    text-align: left;
    padding: 0;
    width: auto;
    appearance: none;
    border: none;
    background-color: transparent;

    &:focus {
        outline: none;
    }
}

ul.clear {
    list-style: none;
    padding: 0;
}

.cleanList {
    list-style: none;
    margin: 0;
    padding: 0;
}

.hideMdUp {
    @include media-breakpoint-up(md) {
        display: none !important;
    }
}

.hideLgUp {
    @include media-breakpoint-up(lg) {
        display: none !important;
    }
}

.hideXLUp {
    @include media-breakpoint-up(xl) {
        display: none !important;
    }
}

.hideXXLUp {
    @include media-breakpoint-up(xxl) {
        display: none !important;
    }
}

.showMdUp {
    display: none;
    @include media-breakpoint-up(md) {
        display: block;
    }
}
.showLgUp {
    display: none;
    @include media-breakpoint-up(lg) {
        display: block;
    }
}

.showXLUp {
    display: none;
    @include media-breakpoint-up(xl) {
        display: block;
    }
}
.showXXLUp {
    display: none;
    @include media-breakpoint-up(xxl) {
        display: block;
    }
}
